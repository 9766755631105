<template>
  <div>
    <el-container>
      <el-header v-if="terminal=='pc'">
        <el-row justify="space-between">
          <el-menu :unique-opened="true" :default-active="routepath" router :collapse="isCollapse" mode="horizontal" background-color='#141c23' text-color="#fff" active-text-color="#ffd04b">
            <!-- <el-menu-item v-for='(item,index) in sectionlist' @click="getMenu(item.name)" :key='index'>{{item.title}}</el-menu-item> -->
            <!-- <el-menu-item style="color:gray;" :key="8" @click="showVersionDiv=true">当前版本：v{{version}}</el-menu-item> -->
            <el-submenu v-for="(item,index) in menulist" :index="item.menuid+''" :key="index" router style="text-align:left">
              <template slot="title">
                <span>{{item.title}}</span>
              </template>
              <el-menu-item-group v-for="(it,ind) in item.children" :index="it.menuid+''" :key="ind">
                <el-menu-item :index="'/'+it.controller+'/'+it.action" style="padding-left:50px;">{{it.title}}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-menu-item @click="logout" style="float:right">退出</el-menu-item>
            <el-menu-item @click="mdfyPswd" style="float:right">修改密码</el-menu-item>
            <el-menu-item @click="refreshMenu" style="float:right">刷新菜单</el-menu-item>
            <el-menu-item style="float:right;color:red;cursor: default;">{{username}}</el-menu-item>
          </el-menu>
        </el-row>
      </el-header>
      <el-header v-else class="flex space-between align-center">
        尊敬的{{username}}，欢迎！
        <i class="el-icon-s-operation" style="font-size: 30px;" @click="drawer = true"></i>
      </el-header>
      <el-container class="maincontent">
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog :visible.sync="chngPswdDiv" :close-on-click-modal="false" @closed="resetInfo('pswdinfo')">
      <el-row>
        <el-input v-model="pswdinfo.oldpassword" autocomplete="off" type="password" placeholder="请输入旧密码"></el-input>
      </el-row>
      <el-row>
        <el-input v-model="pswdinfo.newpassword" autocomplete="off" type="password" placeholder="请输入新密码"></el-input>
      </el-row>
      <el-row>
        <el-input v-model="cfrmnewpassword" autocomplete="off" type="password" placeholder="请再次输入新密码"></el-input>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="chngPswdDiv = false">取 消</el-button>
        <el-button type="primary" @click="chngpswd">确 定</el-button>
      </div>
    </el-dialog>
    <el-drawer :visible.sync="drawer" direction="ttb" size="100%">
      <el-menu :unique-opened="true" :default-active="routepath" router text-color="#333" class="text-left" active-text-color="#ffd04b">
        <el-submenu v-for="(item,index) in menulist" :index="item.menuid+''" :key="index" router style="text-align:left">
          <template slot="title">
            <span>{{item.title}}</span>
          </template>
          <el-menu-item-group v-for="(it,ind) in item.children" :index="it.menuid+''" :key="ind">
            <el-menu-item :index="'/'+it.controller+'/'+it.action" style="padding-left:50px;">{{it.title}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <el-menu-item @click="mdfyPswd">修改密码</el-menu-item>
        <el-menu-item @click="logout">退出</el-menu-item>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      webname: sessionStorage.getItem("webname"),
      menulist: JSON.parse(sessionStorage.getItem("menulist")),
      isCollapse: false,
      chngPswdDiv: false,
      pswdinfo: {
        oldpassword: "",
        newpassword: "",
      },
      cfrmnewpassword: "",
      formLabelWidth: "150px",
      username: sessionStorage.getItem("name"),
      drawer: false,
    };
  },
  methods: {
    ...mapActions({
      changePswd: "auth/changePassword",
    }),
    chngpswd() {
      if (this.pswdinfo.newpassword != this.pswdinfo.cfrmnewpassword) {
        this.$message({
          showClose: true,
          type: "error",
          message: "两次输入的新密码不一致",
        });
      } else {
        this.$axios({
          method: "post",
          url: this.HOST + "/index/chngPswd",
          data: {
            info: this.pswdinfo,
          },
        }).then((res) => {
          if (res.status == 1) {
            this.$message({
              showClose: true,
              type: "info",
              message: "修改成功",
            });
            this.chngPswdDiv = false;
          }
        });
      }
    },
    mdfyPswd() {
      this.saveInfo("pswdinfo");
      this.chngPswdDiv = true;
    },
    refreshMenu() {
      this.$axios({
        url: this.HOST + "/menu/refreshMenu",
        method: "post",
        data: {},
      }).then((res) => {
        if (res.status == 1) {
          this.menulist = res.list;
          sessionStorage.setItem("menulist", JSON.stringify(res.list));
        }
      });
    },
    logout() {
      sessionStorage.removeItem("uuid");
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("account");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("islogin");
      this.$router.push("/login");
    },
  },
  computed: {
    ...mapState({
      navList: (state) => state.navBar.navList,
    }),
    routepath() {
      return this.$route.path;
    },
  },
  created() {},
};
</script>
